@font-face {
font-family: 'SuisseIntl';
src: url(/_next/static/media/f5d54ca501d82d1c-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'SuisseIntl';
src: url(/_next/static/media/544580942f9361d4-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'SuisseIntl';
src: url(/_next/static/media/071bddcd7596d4c7-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'SuisseIntl';
src: url(/_next/static/media/66d16ed81990922c-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: normal;
}@font-face {font-family: 'SuisseIntl Fallback';src: local("Arial");ascent-override: 95.68%;descent-override: 30.18%;line-gap-override: 0.00%;size-adjust: 103.05%
}.__className_2ab962 {font-family: 'SuisseIntl', 'SuisseIntl Fallback'
}

@font-face {
font-family: 'SuisseWorks';
src: url(/_next/static/media/d15dfd69b329033e-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'SuisseWorks';
src: url(/_next/static/media/731cb0e0b76c46b9-s.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'SuisseWorks';
src: url(/_next/static/media/c17847b245ca75f3-s.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'SuisseWorks';
src: url(/_next/static/media/b51e2e6badbf7dfd-s.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'SuisseWorks';
src: url(/_next/static/media/97c1bff192732ba8-s.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'SuisseWorks Fallback';src: local("Times New Roman");ascent-override: 82.77%;descent-override: 26.11%;line-gap-override: 0.00%;size-adjust: 119.12%
}.__className_275abc {font-family: 'SuisseWorks', 'SuisseWorks Fallback'
}

@font-face {
font-family: 'TRRFont';
src: url(/_next/static/media/412371b3628fb9ef-s.woff) format('woff');
font-display: swap;
}@font-face {font-family: 'TRRFont Fallback';src: local("Times New Roman");ascent-override: 66.81%;descent-override: 21.07%;line-gap-override: 0.00%;size-adjust: 147.59%
}.__className_446a10 {font-family: 'TRRFont', 'TRRFont Fallback'
}

@font-face {
    font-family: 'TheRealReal-MySalesPage';
      src: url(/_next/static/media/TheRealReal-Icons.12193305.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  .trr-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'TheRealReal-MySalesPage' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .trr-icon-double-chevron-left:before {
    content: "\e908";
  }
  .trr-icon-double-chevron-right:before {
    content: "\e909";
  }
  .trr-icon-check:before {
    content: "\e905";
  }
  .trr-icon-dollar-sign:before {
    content: "\e901";
  }
  .trr-icon-exclamation:before {
    content: "\e900";
  }
  .trr-icon-check-small:before {
    content: "\e902";
  }
  .trr-icon-user:before {
    content: "\e903";
  }
  .trr-icon-location:before {
    content: "\e904";
  }
  .trr-icon-truck:before {
    content: "\e906";
  }
  .trr-icon-bell:before {
    content: "\e907";
  }

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

 blockquote,
 dl,
 dd,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 hr,
 figure,
 p,
 pre {
   margin: 0;
 }

 button {
   background-color: transparent;
   background-image: none;
 }

 /**
  * Work around a Firefox/IE bug where the transparent `button` background
  * results in a loss of the default `button` focus styles.
  */

 button:focus {
   outline: 1px dotted;
   outline: 5px auto -webkit-focus-ring-color;
 }

 fieldset {
   margin: 0;
   padding: 0;
 }

 ol,
 ul {
   list-style: none;
   margin: 0;
   padding: 0;
 }

 /**
  * 1. Use the user's configured `sans` font-family as a sane default.
  * 2. Use default "normal" line-height so the user isn't forced
  *    to override it to ensure consistency even when using the default theme.
  */

 html, body {
   font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif; /* 1 */
   line-height: 1.5; /* 2 */
 }

 /**
  * 1. Prevent padding and border from affecting element width.
  *
  *    We used to set this in the html element and inherit from
  *    the parent element for everything else. This caused issues
  *    in shadow-dom-enhanced elements like <details> where the content
  *    is wrapped by a div with box-sizing set to `content-box`.
  *
  *    https://github.com/mozdevs/cssremedy/issues/4
  *
  *
  * 2. Allow adding a border to an element by just adding a border-width.
  *
  *    By default, the way the browser specifies that an element should have no
  *    border is by setting it's border-style to `none` in the user-agent
  *    stylesheet.
  *
  *    In order to easily add borders to elements by just setting the `border-width`
  *    property, we change the default border-style for all elements to `solid`, and
  *    use border-width to hide them instead. This way our `border` utilities only
  *    need to set the `border-width` property instead of the entire `border`
  *    shorthand, making our border utilities much more straightforward to compose.
  */

 *,
 ::before,
 ::after {
   box-sizing: border-box; /* 1 */
   border-width: 0; /* 2 */
   border-style: solid; /* 2 */
   border-color: #333 /* 2 */
 }

 /*
  * Ensure horizontal rules are visible by default
  */

 hr {
   border-top-width: 1px;
 }

 /**
  * Undo the `border-style: none` reset that Normalize applies to images so that
  * our `border-{width}` utilities have the expected effect.
  *
  * The Normalize reset is unnecessary for us since we default the border-width
  * to 0 on all elements.
  *
  */

 img {
   border-style: solid;
 }

 textarea {
   resize: vertical;
 }

 input::placeholder,
 textarea::placeholder {
   color: #a1a1aa;
 }

 button,
 [role="button"] {
   cursor: pointer;
 }

 table {
   border-collapse: collapse;
 }

 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-size: inherit;
   font-weight: inherit;
 }

 /**
  * Reset links to optimize for opt-in styling instead of
  * opt-out.
  */

 a {
   color: inherit;
   text-decoration: inherit;
 }

 /**
  * Reset form element properties that are easy to forget to
  * style explicitly so you don't inadvertently introduce
  * styles that deviate from your design system. These styles
  * supplement a partial reset that is already applied by
  * normalize.css.
  */

 button,
 input,
 optgroup,
 select,
 textarea {
   padding: 0;
   line-height: inherit;
   color: inherit;
 }

 /**
  * Use the configured 'mono' font family for elements that
  * are expected to be rendered with a monospace font, falling
  * back to the system monospace stack if there is no configured
  * 'mono' font family.
  */

 pre,
 code,
 kbd,
 samp {
   font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
 }

 /**
  * Make replaced elements `display: block` by default as that's
  * the behavior you want almost all of the time. Inspired by
  * CSS Remedy, with `svg` added as well.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */

 img,
 svg,
 video,
 canvas,
 audio,
 iframe,
 embed,
 object {
   display: block;
   vertical-align: middle;
 }

 /**
  * Constrain images and videos to the parent width and preserve
  * their instrinsic aspect ratio.
  *
  * https://github.com/mozdevs/cssremedy/issues/14
  */

 img,
 video {
   max-width: 100%;
   height: auto;
 }

html,
body {
  padding: 0;
  margin: 0;
  font-family: "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif";
  line-height: 1.6;
  font-size: 16px;
  color: #333;
}

html, body, #__next {
  height: 100%;
}

/* Enable smooth scroll */
html {
	scroll-behavior: smooth;
}

/* Disable smooth scrolling when users have prefers-reduced-motion enabled */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

/* Prevents page scroll when there's any element with this data attribute */
html:has([data-block-page-scroll]),
body:has([data-block-page-scroll]) {
  overflow-y: hidden;
}

/*
 * iOS does not respect overflow-y: hidden on `html` and `body` tags, so we
 * need to add it to a wrapper
 */
#__next:has([data-block-page-scroll]) {
  max-height: 100vh; /* fallback for browsers that do not support dvh */
  max-height: 100dvh; /* dvh is needed for iOS Safari */
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

small {
  font-size: 14px;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: auto !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

#google-sign-in-button > div > div:first-child {
  display: none;
}

