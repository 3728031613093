html,
body {
  padding: 0;
  margin: 0;
  font-family: "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif";
  line-height: 1.6;
  font-size: 16px;
  color: #333;
}

html, body, #__next {
  height: 100%;
}

/* Enable smooth scroll */
html {
	scroll-behavior: smooth;
}

/* Disable smooth scrolling when users have prefers-reduced-motion enabled */
 @media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

/* Prevents page scroll when there's any element with this data attribute */
html:has([data-block-page-scroll]),
body:has([data-block-page-scroll]) {
  overflow-y: hidden;
}

/*
 * iOS does not respect overflow-y: hidden on `html` and `body` tags, so we
 * need to add it to a wrapper
 */
#__next:has([data-block-page-scroll]) {
  max-height: 100vh; /* fallback for browsers that do not support dvh */
  max-height: 100dvh; /* dvh is needed for iOS Safari */
  overflow-y: hidden;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

a {
  color: #0070f3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

small {
  font-size: 14px;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: auto !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

#google-sign-in-button > div > div:first-child {
  display: none;
}
