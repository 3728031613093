@font-face {
    font-family: 'TheRealReal-MySalesPage';
      src: url('../public/fonts/TheRealReal-Icons.woff?ci47yq') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  .trr-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'TheRealReal-MySalesPage' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .trr-icon-double-chevron-left:before {
    content: "\e908";
  }
  .trr-icon-double-chevron-right:before {
    content: "\e909";
  }
  .trr-icon-check:before {
    content: "\e905";
  }
  .trr-icon-dollar-sign:before {
    content: "\e901";
  }
  .trr-icon-exclamation:before {
    content: "\e900";
  }
  .trr-icon-check-small:before {
    content: "\e902";
  }
  .trr-icon-user:before {
    content: "\e903";
  }
  .trr-icon-location:before {
    content: "\e904";
  }
  .trr-icon-truck:before {
    content: "\e906";
  }
  .trr-icon-bell:before {
    content: "\e907";
  }
